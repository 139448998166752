import React from "react"
import {
  ArticlePane,
  ArticlesTabContent
} from "../components/articlePanes"
import {
  ContributorPane,
  ContributorPaneArticles
} from "../components/contributorPanes"
import {
  PeriodicalPane,
  PeriodicalPaneArticles
} from "../components/periodicalPanes"
import {
  SchoolPane,
  SchoolPaneContributors
} from "../components/schoolPanes"
import 'semantic-ui-css/semantic.min.css'

const itemTabPanes = (handleClick, selection) => {

  const fieldsList = {
    all: "*",
    articles: "*,article_types.article_type_id.value,periodicals.periodical_id.title",
    articlesForContributors: "articles.article_id.id,articles.article_id.title,articles.article_id.periodicals.periodical_id.title,articles.article_id.article_year,articles.article_id.month_id,articles.article_id.day",
    articlesForPeriodicals: "articles.article_id.title, articles.article_id.id,articles.article_id.article_year,articles.article_id.month_id,articles.article_id.day",
    contributors: "*,nationalities.nationality_id.name",
    contributorsForOther: "contributors.contributor_id.id,contributors.contributor_id.full_name,contributors.contributor_id.biography",
    periodical: "id,notes,title,frequencies.frequency_id.value",
    periodicalsForArticles: "periodicals.periodical_id.id,periodicals.periodical_id.title,periodicals.periodical_id.comment",
    schools: "schools.school_id.name,schools.school_id.id"
  };

  // contributorsForPeriodicals: "articles.contributors.contributor_id.id,articles.contributors.contributor_id.full_name",

  const fetchData = (fields) => (
    fetch(`https://admin.curranindex.org/items/${selection.index}s/${selection.id}?fields=${fieldsList[fields]}&deep={%22articles%22:%20{%20%22_limit%22:%20-1%20}}`, {
      mode: 'cors'
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then(data => data.data)
      .catch(error => {
        console.error("Error fetching data: " + error);
      })
  );

  switch(selection.index) {
    case 'article':
      return [
      {
        menuItem: { key: 'details', icon: "file text outline", content: 'Details' },
        render: () => (
          <ArticlePane
            fetchData={fetchData}
            handleClick={handleClick}
          />
        )
      }
    ]
    case 'contributor':
      return [
        {
          menuItem: { key: 'details', icon: "user", content: 'Details' },
          render: () => (
            <ContributorPane
              fetchData={fetchData}
              handleClick={handleClick}
            />
          )
        },
        {
          menuItem: { key: 'articles', content: <ArticlesTabContent selection={selection}/> },
          render: () => (
            <ContributorPaneArticles
              fetchData={fetchData}
              handleClick={handleClick}
              index={selection.index}
            />
          )
        }
      ]
    case 'periodical':
      return [
        {
          menuItem: { key: 'details', icon: "newspaper outline", content: 'Details' },
          render: () => (
            <PeriodicalPane
              fetchData={() => fetchData("periodical")}
            />
          )
        },
        {
          menuItem: { key: 'articles', content: <ArticlesTabContent selection={selection}/> },
          render: () => (
            <PeriodicalPaneArticles
              fetchData={() => fetchData("articlesForPeriodicals")}
              handleClick={handleClick}
              index={selection.index}
            />
          )
        }
      ]
    case 'school':
      return [
        {
          menuItem: { key: 'details', icon: "building outline", content: 'Details' },
          render: () => (
            <SchoolPane
              fetchData={() => fetchData("all")}
            />
          )
        },
        {
          menuItem: { key: 'contributors', icon: "user", content: 'Contributors' },
          render: () => (
            <SchoolPaneContributors
              fetchData={() => fetchData("contributorsForOther")}
              handleClick={handleClick}
              index={selection.index}
            />
          )
        }
      ]
    default:
      return null;
  }
}

export default itemTabPanes
