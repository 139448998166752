import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import qs from 'qs';
import itemTabPanes from "../components/itemTabPanes"
import { capitalize } from "../components/utilities"
import 'semantic-ui-css/semantic.min.css'
import {
  Button,
  Dimmer,
  Label,
  Loader,
  Modal,
  Segment,
  Tab
} from 'semantic-ui-react'

const ItemModal = ({ onClose, selection, showModal }) => {
  const location = useLocation();
  const createURL = state => `?${qs.stringify(state)}`;
  const searchStateToUrl = searchState => searchState ? createURL(searchState) : '';
  const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

  const [isLoading, setIsLoading] = useState(false);
  const [itemHistory, setItemHistory] = useState([]);
  const [selectedItem, setSelectedItem] = useState(urlToSearchState(location).selectedItem || selection);
  const [modalHeader, setModalHeader] = useState("");
  const [disableCopyUrl, setDisableCopyUrl] = useState(false);

  const copyUrl = () => {
    setDisableCopyUrl(true);
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => setDisableCopyUrl(false), 1500);
  };

  const header = {
    article: "title",
    contributor: "full_name",
    periodical: "title",
    school: "name"
  }

  const fetchHeader = () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    fetch(`https://admin.curranindex.org/items/${selectedItem.index}s/${selectedItem.id}?fields=${header[selectedItem.index]}`, {
      mode: 'cors'
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then(data => {
        setModalHeader(data.data[header[selectedItem.index]]);
      })
      .catch(error => {
        console.error("Error fetching data: " + error);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (selectedItem && showModal) {
      fetchHeader();
      let lastItem = itemHistory[itemHistory.length - 1];
      if (!(JSON.stringify(lastItem) === JSON.stringify(selectedItem))) {
        setItemHistory([
          ...itemHistory,
          selectedItem
        ]);
      }
    }
  },[selectedItem, showModal]);

  useEffect(() => {
    window.history.pushState(null, "", searchStateToUrl({ selectedItem, showModal }));
  },[selectedItem, showModal]);

  const handleBackClick = () => {
    itemHistory.pop();
    setItemHistory(itemHistory);
    let lastItem = itemHistory[itemHistory.length - 1];
    setSelectedItem(lastItem);
  }

  const handleClose = () => {
    onClose();
    navigate(searchStateToUrl({}),{ replace: true });
  }

  const handleItemClick = (selection) => {
    setSelectedItem(selection);
  }

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      open={showModal}
      size={'large'}
    >
        <Modal.Header>
          { !isLoading && selectedItem &&
            <><Label horizontal size={'large'}>{capitalize(selectedItem.index)}</Label> {modalHeader}</>
          }
        </Modal.Header>
        <Modal.Content>
        { isLoading && <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>}

        { !isLoading && selectedItem && (
            <Modal.Description>
              <Tab
                defaultActiveIndex={0}
                menu={{ fluid: true, vertical: true }}
                menuPosition='left'
                panes={itemTabPanes(handleItemClick, selectedItem)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={disableCopyUrl}
            onClick={copyUrl}
          >
            {disableCopyUrl ? "Copied!" : "Copy Link"}
          </Button>
          <Button
            disabled={itemHistory.length < 2}
            onClick={handleBackClick}
          >
            Back
          </Button>
          <Button
            color='black'
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Actions>
    </Modal>
  );
}

export default ItemModal
