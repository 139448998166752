import React, { useState, useEffect } from "react"
import { monthStrings } from "./utilities"
import 'semantic-ui-css/semantic.min.css'
import {
  Dimmer,
  Loader,
  Segment,
  Tab
} from 'semantic-ui-react'
import useArticleRange from "../hooks/useArticleRange"

export const PeriodicalPane = ({ fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [periodical, setPeriodical] = useState({});

  useEffect(() => {
    setIsLoading(true);
    fetchData()
      .then(data => setPeriodical(data))
      .finally(() => setIsLoading(false))
  }, [])

  const yearRange = useArticleRange(periodical)

  return (
    <Tab.Pane>
      { isLoading &&
        <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
      }
      { !isLoading &&
        <>
          <div className={"detail"}>
            {yearRange ? yearRange[0] : "---" }
          </div>
          <div className={"detail-label"}>EARLIEST ARTICLE YEAR</div>

          <div className={"detail"}>
            {yearRange ? yearRange[1] : "---" }
          </div>
          <div className={"detail-label"}>LATEST ARTICLE YEAR</div>

          <br />
        </>
      }

      { !isLoading && periodical?.frequencies &&
        <>
          <div className={"detail"} dangerouslySetInnerHTML={{__html: periodical?.frequencies.map(f => f.frequency_id.value).join(", ")}} />
          <hr style={{margin: "8px 0px"}}/>
          <div className={"detail-label"}>FREQUENCY</div>
        </>
      }

      { !isLoading && periodical?.notes &&
        <>
          <div className={"detail"} dangerouslySetInnerHTML={{__html: periodical?.notes}} />
          <hr style={{margin: "8px 0px"}}/>
          <div className={"detail-label"}>NOTES</div>
        </>
      }

    </Tab.Pane>
  );
}

export const PeriodicalPaneArticles = ({ fetchData, handleClick, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const compare = (a, b) => (a > b) - (a < b);

  useEffect(() => {
    setIsLoading(true);
    fetchData()
      .then(data => data.articles.map(aObj => aObj.article_id).sort((a,b) => (
        compare(a.article_year, b.article_year) || compare(a.month_id, b.month_id) || compare(a.day, b.day)
      )))
      .then(sortedData => setArticles(sortedData))
      .finally(() => setIsLoading(false))
  }, [])

  return articles.length > 0 ?
    (
      <Tab.Pane>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <ul>
            {
              articles.map((article) => (
                <li className="faux-link" onClick={() => handleClick({ index: "article", id: article.id })}>
                  {article.title} ({article.day}{article.day ? " " : ""}{monthStrings[article.month_id?.toString()]} {article.article_year})
                </li>
              ))
            }
          </ul>
        }
      </Tab.Pane>
    )
  :
    (
      <Tab.Pane>
      { isLoading &&
        <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
      }
      { !isLoading &&
        <i> No articles associated with this {index}</i>
      }
      </Tab.Pane>
    )
}
