import { useEffect, useState } from 'react'

// Get the year range of articles for a given periodical.
const useArticleRange = (periodical) => {
  const [articleRange, setArticleRange] = useState(null);

    useEffect(() => {
      if (periodical.id) {
        fetch(`https://admin.curranindex.org/items/articles?filter[periodicals][periodical_id]=${periodical.id}&aggregate[min]=article_year&aggregate[max]=article_year`, {
          mode: 'cors'
        })
        .then(res => {
          if (res.ok) {
            return res.json()
          }
          throw res
        })
        .then(res => {
          if (res.data && res.data[0].min?.article_year && res.data[0].max?.article_year) {
            setArticleRange([res.data[0].min.article_year, res.data[0].max.article_year])
          }
        })
        .catch(error => {
          console.error("Error fetching data: " + error);
        })
      }
    }, [periodical])

    return articleRange
}

export default useArticleRange
