import React, { useState, useEffect } from "react"
import { capitalize, monthStrings } from "../components/utilities"
import 'semantic-ui-css/semantic.min.css'
import {
  Dimmer,
  Loader,
  Segment,
  Tab
} from 'semantic-ui-react'

export const ContributorPane = ({ fetchData, handleClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contributor, setContributor] = useState({});

  useEffect(() => {
    setIsLoading(true);
    fetchData("contributors")
      .then(data => setContributor(data))
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Tab.Pane>
      { isLoading &&
        <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
      }
      { !isLoading && contributor &&
        <>
          <div className={"detail"}>
            {contributor.birth_year || "---" }
          </div>
          <div className={"detail-label"}>BIRTH</div>

          <div className={"detail"}>
            {contributor.death_year || "---" }
          </div>
          <div className={"detail-label"}>DEATH</div>

          <div className={"detail"}>
            {contributor.nationalities ? contributor.nationalities.map(n => n.nationality_id.name).join(", ") : "---" }
          </div>
          <div className={"detail-label"}>NATIONALITY</div>

          <div className={"detail"}>
            {capitalize(contributor.gender || "---" )}
          </div>
          <div className={"detail-label"}>GENDER</div>

          <div className={"detail"}>
            <ContributorPaneSchools
              fetchData={fetchData}
              handleClick={handleClick}
              index={"contributor"}
            />
          </div>
          <div className={"detail-label"}>SCHOOLS</div>
        </>
      }
      <div />
      { !isLoading && contributor?.biography &&
        <>
          <div className={"detail"} dangerouslySetInnerHTML={{__html: contributor?.biography}} />
          <div className={"detail-label"}>BIOGRAPHY</div>
        </>
      }
    </Tab.Pane>
  );
}

export const ContributorPaneArticles = ({ fetchData, handleClick, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const compare = (a, b) => (a > b) - (a < b);

  useEffect(() => {
    fetchData("articlesForContributors")
      .then(data => data.articles.sort(
        (a, b) => (
          compare(a.article_id.article_year, b.article_id.article_year) || compare(a.article_id.month_id, b.article_id.month_id) || compare(a.article_id.day, b.article_id.day)
        )
      ))
      .then(sortedData => setArticles(sortedData))
  }, [])

  return articles.length > 0 ?
    (
      <Tab.Pane>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <ul>
            {articles.map((article) => (
              <li className="faux-link" onClick={() => handleClick({ index: "article", id: article.article_id.id })}>
                  {article.article_id.title} <cite>({article.article_id.periodicals.map(p => p.periodical_id.title).join(", ")}, {article.article_id.day}{article.article_id.day ? " " : ""}{monthStrings[article.article_id.month_id?.toString()]} {article.article_id.article_year})</cite>
              </li>
            ))}
          </ul>
        }
      </Tab.Pane>
    )
  :
    (
      <Tab.Pane>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <i> No articles associated with this {index}</i>
        }
      </Tab.Pane>
    )
}

export const ContributorPaneSchools = ({ fetchData, handleClick, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetchData("schools")
      .then(data => (
        data.schools.sort(
        (a,b) => a.school?.school_id.name - b.school?.school_id.name
      )))
      .then(sortedData => setSchools(sortedData))
      .finally(() => setIsLoading(false))
  }, [])

  return schools?.length > 0 ?
    (
      <span>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <span>
            {
              schools.map((school) => (
                <div className="faux-link" onClick={() => handleClick({ index: "school", id: school.school_id.id })}>
                  {school.school_id.name}
                </div>
              ))
            }
          </span>
        }
      </span>
    )
  :
    (
      <span>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <i> No schools associated with this {index}</i>
        }
      </span>
    )
}
