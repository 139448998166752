import React, { useState } from "react"
import {
  Button
} from 'semantic-ui-react'
import { CSVDownload } from "react-csv";

const CsvDownloadButton = ({ index, searchState }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fieldsToExclude = [
    "articles",
    "date",
    "periodical",
    "periodical_id",
    "periodical_title",
    "schools"
  ];

  const {
    query,
    range,
    refinementList,
    sortBy
  } = searchState;

  const filters = () => {
    const arr = [];
    if (refinementList) {
      Object.keys(refinementList).forEach((k) => {
        arr.push(refinementList[k] !== '' ? [`${k}='${refinementList[k]}'`] : '');
      })
    }
    if (range?.date?.min) {
      arr.push(`date>=${range.date.min}`)
    }
    if (range?.date?.max) {
      arr.push(`date<=${range.date.max}`)
    }
    if (range?.article_year?.min) {
      arr.push(`article_year>=${range.article_year.min}`)
    }
    if (range?.article_year?.max) {
      arr.push(`article_year<=${range.article_year.max}`)
    }
    if (range?.birth_year?.min) {
      arr.push(`birth_year>=${range.birth_year.min}`)
    }
    if (range?.birth_year?.max) {
      arr.push(`birth_year<=${range.birth_year.max}`)
    }
    if (range?.death_year?.min) {
      arr.push(`death_year>=${range.death_year.min}`)
    }
    if (range?.death_year?.max) {
      arr.push(`death_year<=${range.death_year.max}`)
    }
    return arr;
  };

  const body = JSON.stringify({
    "filter": filters(),
    "limit": 999999,
    "sort": sortBy ? [sortBy.split(":").splice(1).join(":")] : null,
    "q": query
  });

  const cleanData = (data) => {
    data.forEach (d => {
      fieldsToExclude.forEach(field => delete d[field])
    });
    return data;
  };

  const fetchData = () => {
    setIsLoading(true);
    fetch(`https://admin.curranindex.org/data/indexes/${index}s/search`, {
      "headers": {
        "content-type": "application/json",
        "Referrer-Policy": "strict-origin-when-cross-origin"
      },
      "body": body,
      "method": "POST"
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then(data => setData(cleanData(data.hits)))
      .catch(error => {
        console.error("Error fetching data: " + error.message);
      })
      .finally(() => setIsLoading(false))
  };

  const onClick = async (searchState) => {
    setData();
    await fetchData();
  };

  return (
    <Button
      basic
      fluid
      loading={isLoading}
      onClick={() => onClick(searchState)}
    >
      <>
        {"Download CSV"}
        { data &&
          <CSVDownload data={data} target="_blank" separator=";" />
        }
      </>
    </Button>
  );
};

export default CsvDownloadButton
