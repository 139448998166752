import React, { useState, useEffect } from "react"
import 'semantic-ui-css/semantic.min.css'
import {
  Dimmer,
  Loader,
  Popup,
  Segment,
  Tab
} from 'semantic-ui-react'

export const SchoolPane = ({ fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [school, setSchool] = useState({});

  useEffect(() => {
    setIsLoading(true);
    fetchData()
      .then(data => setSchool(data))
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Tab.Pane>
    { isLoading &&
      <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
    }
    { !isLoading &&
      <>
        <div className={"detail"}>
          { school.location?.type === "Point" ? school.location.coordinates.join(", ") : "Unrecognized location format" || "---" }
        </div>
        <div className={"detail-label"}>LOCATION</div>
      </>
    }
    </Tab.Pane>
  );
}

export const SchoolPaneContributors = ({ fetchData, handleClick, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contributors, setContributors] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetchData()
      .then(data => data.contributors.sort(
        (a, b) => a.contributor_id.full_name - b.contributor_id.full_name
      ))
      .then(sortedData => setContributors(sortedData))
      .finally(() => setIsLoading(false))
  }, [])

  return contributors.length > 0 ?
    (
      <Tab.Pane>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <ul>
            {
              contributors.map((contributor) => (
                <Popup
                  content={
                    <div dangerouslySetInnerHTML={{__html: contributor.contributor_id.biography?.slice(0,100) + "..."}} />
                  }
                  trigger={
                    <li className="faux-link" onClick={() => handleClick({ index: "contributor", id: contributor.contributor_id.id })}>
                      {contributor.contributor_id.full_name}
                    </li>
                  }
                  wide
                />
              ))
            }
          </ul>
        }
      </Tab.Pane>
    )
  :
    (
      <Tab.Pane>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <i> No contributors associated with this {index}</i>
        }
      </Tab.Pane>
    )

}
