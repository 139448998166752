import React, { useState, useEffect } from "react"
import { capitalize } from "../components/utilities"
import 'semantic-ui-css/semantic.min.css'
import {
  Dimmer,
  Label,
  Loader,
  Popup,
  Segment,
  Tab
} from 'semantic-ui-react'

export const ArticlePane = ({ fetchData, handleClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [article, setArticle] = useState({});

  useEffect(() => {
    setIsLoading(true);
    fetchData("articles")
      .then(data => setArticle(data))
      .finally(() => setIsLoading(false))
  }, [])

  return (
    <Tab.Pane>
      <div>
        <ArticlePanePeriodical
          fetchData={fetchData}
          handleClick={handleClick}
          index={"article"}
        />
        <div className={"detail-label"}>PERIODICAL</div>

        <ArticlePaneContributors
          fetchData={fetchData}
          handleClick={handleClick}
          index={"article"}
        />
        <div className={"detail-label"}>CONTRIBUTORS</div>

        <div className={"detail"}>
          { article?.day || "---" }
        </div>
        <div className={"detail-label"}>ARTICLE DAY</div>

        <div className={"detail"}>
          { article?.month_id || "---" }
        </div>
        <div className={"detail-label"}>ARTICLE MONTH</div>

        <div className={"detail"}>
          { article?.article_year || "---" }
        </div>
        <div className={"detail-label"}>ARTICLE YEAR</div>

        <div className={"detail"}>
          Vol. { article?.volume || "---" }
        </div>
        <div className={"detail-label"}>VOLUME</div>

        <div className={"detail"}>
          pp. { article?.page_start || "---" } - pp. { article?.page_end || "---" }
        </div>
        <div className={"detail-label"}>PAGES</div>

        <div className={"detail"}>
          {capitalize(article?.article_types?.map(at => at.article_type_id.value).join(", ") || "---" )}
        </div>
        <div className={"detail-label"}>ARTICLE TYPE(S)</div>

        <div className={"detail"}>
          {article?.payment || "---" }
        </div>
        <div className={"detail-label"}>PAYMENT</div>

        <div className={"detail"}>
          {article?.attribution || "---" }
        </div>
        <div className={"detail-label"}>ATTRIBUTION</div>

        <div className={"detail"}>
            {article?.attribution_confidence || "---" }
        </div>
        <div className={"detail-label"}>ATTRIBUTION CONFIDENCE</div>

        <div className={"detail"}>
            {article?.notes || "---" }
        </div>
        <div className={"detail-label"}>NOTES</div>
      </div>
    </Tab.Pane>
  );
}

export const ArticlePanePeriodical = ({ fetchData, handleClick, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [periodicals, setPeriodicals] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetchData("periodicalsForArticles")
      .then(data => setPeriodicals(data.periodicals))
      .finally(() => setIsLoading(false))
  }, [])

  return periodicals?.length ?
    periodicals.map(periodical => (
      <div>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <Popup
            content={
              <div dangerouslySetInnerHTML={{__html: periodical.periodical_id.comment?.slice(0,100) + "..."}} />
            }
            trigger={
              <div className={"faux-link"} onClick={() => handleClick({ index: "periodical", id:  periodical.periodical_id.id })}>
                {periodical.periodical_id.title}
              </div>
            }
            wide
          />
        }
      </div>
    ))
  :
    (
      <div>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <i>No periodical associated with this {index}</i>
        }
      </div>
    )

}

export const ArticlePaneContributors = ({ fetchData, handleClick, index }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contributors, setContributors] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetchData("contributorsForOther")
      .then(data => {
        setContributors(data.contributors.filter(c => c.contributor_id).sort(
          (a, b) => a.contributor_id.full_name - b.contributor_id.full_name
        ))
      })
      .finally(() => setIsLoading(false))
  }, [])

  return contributors.length > 0 ?
    (
      <div>
        { isLoading &&
          <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
        }
        { !isLoading &&
          <span>
            {
              contributors.map((contributor) => (
                <Popup
                  content={
                    <div dangerouslySetInnerHTML={{__html: contributor.contributor_id.biography?.slice(0,100) + "..."}} />
                  }
                  trigger={
                    <div className="faux-link" onClick={() => handleClick({ index: "contributor", id: contributor.contributor_id.id })}>
                      {contributor.contributor_id.full_name}
                    </div>
                  }
                  wide
                />
              ))
            }
          </span>
        }
      </div>
    )
  :
    <div>
      { isLoading &&
        <Segment style={{height: "300px"}}><Dimmer active inverted><Loader content='Loading' inverted /></Dimmer></Segment>
      }
      { !isLoading &&
        <i> No contributors associated with this {index}</i>
      }
    </div>

}

export const ArticlesTabContent = ({ selection }) => {
  const [articlesCount, setArticlesCount] = useState([]);
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  };

  useEffect(() => {
    fetch(`https://admin.curranindex.org/items/${selection.index}s/${selection.id}?fields=count(articles)`, {
    mode: 'cors'
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      }
      throw response
    })
    .then(data => data.data.articles_count)
    .then(count => setArticlesCount(count))
  }, []);

  return (
    <div style={style}>
      <span>Articles</span>
      <Label>{articlesCount}</Label>
    </div>
  )
}
